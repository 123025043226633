import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';




@Injectable({ providedIn: "root" })
export class CommonService {

  constructor(private toastr: ToastrService) {

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getSourceName(sourceLink: any) {
    let orgLink = sourceLink;//'https://www.vice.com/en_us/article/889974/navy-releases-new-ufo-incident-reports';
    // this.childData.articleOrgLink = orgLink;
    let originalSourceLink = orgLink;
    if (orgLink.indexOf("https") >= 0) {
      originalSourceLink = orgLink.slice(8, orgLink.length);
    } else if (orgLink.indexOf("http") >= 0) {
      originalSourceLink = orgLink.slice(7, orgLink.length);
    }
    let sourceName = originalSourceLink;

    if (sourceName.indexOf("www") >= 0) {
      sourceName = sourceName
        ? sourceName.slice(4, sourceName.length)
        : sourceName;
    }

    return sourceName;
  }


  success(msg: any) {
    this.toastr.success(msg);
  }
  error(error: any) {
    this.toastr.error(error);
  }

}