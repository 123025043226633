import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { User } from '../schema/user';

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    Api_url: string;
    redirectLocalVal:any = {};
    showSuccess(msg: any) {
        this.toastr.success(msg);
    }
    showError(error: any) {
        this.toastr.error(error);
    }
    
    public currentUserSubject: BehaviorSubject<User | null>;
    public currentUser: Observable<User | null>; // Updated to User | null

    public MenuHideAndShowObject: BehaviorSubject<any>;
    public MenuHideAndShow: Observable<boolean>;

    public isExpandMenuObject: BehaviorSubject<any>;
    public isExpandMenu: Observable<boolean>;
    constructor( private toastr: ToastrService) {
        this.currentUserSubject = new BehaviorSubject<User | null>(null);
        this.Api_url = environment.APIUrl;

        const storedUserJSON = sessionStorage.getItem('currentUser');
        const storedUser = storedUserJSON ? JSON.parse(storedUserJSON) : null;

        this.currentUserSubject.next(storedUser); // Use next() to emit the initial value
        this.currentUser = this.currentUserSubject.asObservable();


        this.MenuHideAndShowObject = new BehaviorSubject<any>([]);
        this.MenuHideAndShow = this.MenuHideAndShowObject.asObservable();

        this.isExpandMenuObject = new BehaviorSubject<boolean>(true);
        this.isExpandMenu = this.isExpandMenuObject.asObservable()
    }

    hideAndShowMenu(val: any) {
        this.MenuHideAndShowObject.next([{ hideAndShow: val }]);
    }

    public updatedLocalStorage(data: any){
        this.currentUserSubject.next(data);
    }

    
}