<div class="modal fade show" id="modal-xl" aria-modal="true" style="display: block; padding-right: 16px;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Choose Template</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <figure class="imghvr-hinge-down" #temp1>

                    <div style="display: none;" class="closeButton"></div>
                    <img src="http://13.84.216.173:8081/images/template18.jpg" alt="example-image">
                    <figcaption>

                        <p>
                        </p>
                        <div class="btn btn-info" (click)="addTemplate(temp1)"> Add This Template </div>
                        <p></p>
                    </figcaption><a href="javascript:;" class="TemplateSelection"></a>

                </figure>
                <figure class="imghvr-hinge-down">

                    <div class="closeButton" style="display: none;"></div>
                    <img src="http://13.84.216.173:8081/images/template19.jpg" alt="example-image">
                    <figcaption>

                        <p>
                        </p>
                        <div class="btn btn-info">Add This Template</div>
                        <p></p>
                    </figcaption><a href="javascript:;" class="TemplateSelection"></a>
                </figure>


            </div>

        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>