import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { first } from 'rxjs';
import { HttpService } from './common_service/http.service';
import { User } from './schema/user';
import { environment } from './environments/environment';
import { AuthenticationService } from './common_service/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User;
  first_name!: string;
  last_name!: string;
  isExpanded = true;
  showSubmenu: boolean = false;
  ParentSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showAccountSub: boolean = false;
  showCalendarSub: boolean = false;
  showcalendarSub: boolean = false;
  showEmailSub: boolean = false;
  showLeadSub: boolean = false;
  showPromoSub: boolean = false;
  showWebsiteSub: boolean = false;
  showContentSub: boolean = false;
  userDetails: any = {};
  data: string = '';
  sessionTimeOutVal: any;
  routerClass: string | undefined;
  permission: string[];
  role: string;
  settings: boolean = false
  campaign: boolean = false
  calendar: boolean = false
  website: boolean = false
  social: boolean = false;
  submitArtwork: boolean = false;
  dashboard: boolean = false
  collapseHide: boolean = false
  currentRoute: string;
  topBar: Object | any = {
    menu: "Dashboard",
    page: ""
  }
  private scriptElement: HTMLScriptElement;
  constructor(
    public router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private service: HttpService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url
          this.dynamicTopBar(this.currentRoute)
        }
      }
    )
    this.titleService.setTitle('Sayshop');
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x as User;
      if (this.currentUser != null) {
        this.first_name = this.currentUser.firstName;
        this.last_name = this.currentUser.lastName;
        this.permission = this.currentUser?.['permission'];
        this.role = this.currentUser?.role;
        this.loadScript(this.currentUser)
      }
    });

    this.authenticationService.MenuHideAndShow.subscribe((x) => {
      if (x != null) {
        if (Array.isArray(x) && x.length) this.isExpanded = x[0].hideAndShow;
      }
    });

    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this.spinner.show();
      }

      if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
        this.spinner.hide();
      }
    });

  }

  ngOnInit() {
    this.sideMenu();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let base = event.url.split('/');
        this.routerClass = base[1];
      }
    });
  }

  @HostListener('window:mousemove') refreshUserState() { }
  routerClassChange() { }
  navTo() {
    this.router.navigate(['/support']);
  }
  sideMenu() {
    this.data = window.location.hash.split('/')[1];
    this.showedSubMenu(this.data);
  }

  sessionTimeout() {
    //   Session Time Out
    this.sessionTimeOutVal = setTimeout(() => {
      this.logout();
    }, 8000000); //  Milli Second
  }

  permissionCheck(word: string) {
    // if (this.role === 'SuperAdmin') {
    //   return true;
    // } else {
    return this.permission.some((str) => str.includes(word));
    //}
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  showedSubMenu(type: string) {
    if (type == 'account') {
      this.showCalendarSub = false;
      this.showEmailSub = false;
      this.showLeadSub = false;
      this.showLeadSub = false;
      this.showWebsiteSub = false;
      this.showPromoSub = false;
      this.showAccountSub = !this.showAccountSub;
    } else if (type == 'calendar') {
      this.showAccountSub = false;
      this.showEmailSub = false;
      this.showLeadSub = false;
      this.showLeadSub = false;
      this.showWebsiteSub = false;
      this.showPromoSub = false;
      this.showCalendarSub = !this.showCalendarSub;
    } else if (type == 'email') {
      this.showAccountSub = false;
      this.showCalendarSub = false;
      this.showLeadSub = false;
      this.showLeadSub = false;
      this.showWebsiteSub = false;
      this.showPromoSub = false;
      this.showEmailSub = !this.showEmailSub;
    } else if (type == 'uniqueoffers') {
      this.showAccountSub = false;
      this.showCalendarSub = false;
      this.showEmailSub = false;
      this.showWebsiteSub = false;
      this.showPromoSub = false;
      this.showLeadSub = !this.showLeadSub;
    } else if (type == 'socialpromo') {
      this.showAccountSub = false;
      this.showCalendarSub = false;
      this.showCalendarSub = false;
      this.showEmailSub = false;
      this.showLeadSub = false;
      this.showWebsiteSub = false;
      this.showPromoSub = !this.showPromoSub;
    } else if (type == 'website') {
      this.showAccountSub = false;
      this.showCalendarSub = false;
      this.showCalendarSub = false;
      this.showEmailSub = false;
      this.showLeadSub = false;
      this.showPromoSub = false;
      this.showWebsiteSub = !this.showWebsiteSub;
    } else if (type == 'content') {
      this.showAccountSub = false;
      this.showCalendarSub = false;
      this.showCalendarSub = false;
      this.showEmailSub = false;
      this.showLeadSub = false;
      this.showPromoSub = false;
      this.showWebsiteSub = false;
      this.showContentSub = !this.showContentSub;
    }
  }
  logout() {
    this.showCalendarSub = false;
    this.showEmailSub = false;
    this.showLeadSub = false;
    this.showLeadSub = false;
    this.showWebsiteSub = false;
    this.showPromoSub = false;
    this.showAccountSub = false;
    sessionStorage.removeItem('currentUser');
    this.authenticationService.currentUserSubject.next(null);
    this.router.navigate(['/user/login']);
    // window.location.reload()

    // else {
    //   this.service
    //     .get(environment.APIUrl + '/User/getUserById' + superadmin_id)
    //     .subscribe({
    //       next: (userDetails: any) => {
    //         let user: any = {
    //           id: userDetails['id'],
    //           username: '',
    //           firstName: userDetails['first_name'],
    //           lastName: userDetails['last_name'],
    //           phoneNo: userDetails['phone_number'],
    //           email: userDetails['email'],
    //           role: userDetails['roles'],
    //           marketId: userDetails['market_id'],
    //           companyId: userDetails['company_id'],
    //           parentId: userDetails['parent_id'],
    //           profileImage:
    //             userDetails['user_personalphoto'] != '' &&
    //               userDetails['user_personalphoto'] != null
    //               ? userDetails['user_personalphoto']
    //               : '../assets/images/user2-160x160.jpg',
    //           personalContent: userDetails['user_personalcontents'],
    //           userLevel:
    //             userDetails['user_level'] != null
    //               ? userDetails['user_level']
    //               : 'A',
    //         };
    //         this.userDetails = userDetails;
    //         sessionStorage.clear();
    //         sessionStorage.setItem('currentUser', JSON.stringify(user));
    //         this.authenticationService.currentUserSubject.next(user)
    //         this.first_name = this.currentUser.firstName;
    //         this.last_name = this.currentUser.lastName;
    //         this.router.navigate(['/setting/users']);
    //       },
    //       error: (error: any) => {
    //         console.log(error)
    //       }
    //     });
    // }
  }

  switchSettings() {
    this.settings = !this.settings;
    this.calendar = false;
    this.website = false;
    this.campaign = false;
    this.dashboard = false;
    this.social = false;
    this.submitArtwork = false
  }

  switchCampaign() {
    this.settings = false;
    this.calendar = false;
    this.website = false;
    this.campaign = !this.campaign;
    this.dashboard = false;
    this.social = false;
    this.submitArtwork = false
  }

  switchSocial() {
    this.settings = false;
    this.calendar = false;
    this.website = false;
    this.campaign = false;
    this.dashboard = false;
    this.social = !this.social;
    this.submitArtwork = false
  }

  switchSubmitArtwork() {
    this.settings = false;
    this.calendar = false;
    this.website = false;
    this.campaign = false;
    this.dashboard = false;
    this.social = false;
    this.submitArtwork = !this.submitArtwork
  }

  switchWebsite() {
    this.website = !this.website;
    this.calendar = false;
    this.settings = false;
    this.campaign = false;
    this.dashboard = false;
    this.social = false;
    this.submitArtwork = false
  }

  switchCalendar() {
    this.calendar = !this.calendar;
    this.settings = false;
    this.website = false;
    this.campaign = false;
    this.dashboard = false;
    this.social = false;
    this.submitArtwork = false
  }

  switchDashboard() {
    this.website = false;
    this.settings = false;
    this.calendar = false;
    this.campaign = false;
    this.social = false;
    this.dashboard = !this.dashboard;
    this.submitArtwork = false
  }

  dynamicTopBar(URL: string) {
    const splitURL = URL.split("/");
    console.log('splitURL::: ', splitURL[1]);
    if (splitURL.length > 1) {
      switch (splitURL[1]) {
        case "dashboard":
          this.topBar.menu = this.capitalizeFirstLetter(splitURL[1])
          this.topBar.page = ""
          break;
        case "submit_artwork":
          this.topBar.menu = "Submit Artwork"
          this.topBar.page = ""
          break;
        case "website":
          this.topBar.menu = splitURL[2] !== 'preview' ? "Website Manager" : "";


          this.topBar.page = splitURL[2] ?
            splitURL[2] === 'landing-page' ? "Website Pages" :
              splitURL[2] === 'custom-domain' ? "Custom Domain" :
                splitURL[2] === 'optinforms' ? "Opt-in Forms" :
                  this.capitalizeFirstLetter(splitURL[2]) : null
          break;
        case "email":
          this.topBar.menu = "Campaign Manager";
          this.topBar.page = splitURL[1] ?
            this.capitalizeFirstLetter(splitURL[2]) : null
          break;
        case "calendar":
          this.topBar.menu = this.capitalizeFirstLetter(splitURL[1])
          this.topBar.page = splitURL[2] ?
            splitURL[2] === 'activeCalendar' ? "Active Calendar" :
              splitURL[2] === 'personalremainder' ? "Personal Reminder" :
                this.capitalizeFirstLetter(splitURL[2]) : null
          break;
        case "setting":
          this.topBar.menu = "Settings";
          this.topBar.page = splitURL[2] ?
            this.capitalizeFirstLetter(splitURL[2]) : null
          break;
        case "social":
          this.topBar.menu = "Social Promo"
          this.topBar.page = splitURL[2] ?
            splitURL[2] === 'socialPost' ? "Social" :
              this.capitalizeFirstLetter(splitURL[2]) : null
          break;
        case "account":
          this.topBar.menu = this.capitalizeFirstLetter(splitURL[1])
          this.topBar.page = splitURL[2] ? (splitURL[2] === 'accountsettings' ? "Account Settings" :
            (this.topBar.page = splitURL[2] ? (splitURL[2] === 'companydetails' ? "Company Details" : "") :
              this.capitalizeFirstLetter(splitURL[2]))) : null
          break;
        default:
          this.topBar.menu = splitURL[1] ? this.capitalizeFirstLetter(splitURL[1]) : "";
          this.topBar.page = splitURL[2] ? this.capitalizeFirstLetter(splitURL[2]) : ""
          break;
      }
    }
  }

  capitalizeFirstLetter(s: string) {
    return s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : s
  }

  collapse() {
    this.collapseHide = !this.collapseHide
  }

  loadScript(user: any): void {
    if (user !== null) {
      console.log('script added')
      this.scriptElement = document.createElement('script');
      this.scriptElement.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      this.scriptElement.id = 'jiraWidget';
      this.scriptElement.setAttribute('async', 'true');
      this.scriptElement.setAttribute('data-jsd-embedded', '');
      this.scriptElement.setAttribute('data-key', '0781668e-dfca-4dec-a6df-1d3fd87714a4');
      this.scriptElement.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');

      this.scriptElement.onload = () => {
        window.document.dispatchEvent(new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true
        }));
      };

      document.body.appendChild(this.scriptElement);
    }
  }


}
