import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent {
  @Output() addTemp = new EventEmitter<string>();
  dialogValue: any;
  selectedTemplates: any = []; //contains all template data 
  constructor(
    public subscribeFormBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.dialogValue = this.data;
  }
  onNoClick(): void {
    //sending back data to landing page
    this.dialogRef.close(this.selectedTemplates);
  }

  addTemplate(template: any) {
    this.selectedTemplates.push(template);
    this.dialogRef.close(this.selectedTemplates); // Don't use this close button; create a done button and call this function
  }
}
