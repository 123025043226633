import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common_service/common.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpService } from "src/app/common_service/http.service";
import { MatPaginator } from "@angular/material/paginator";
import { environment } from '../environments/environment'



@Component({
  selector: 'app-submit-artwork',
  templateUrl: './submit-artwork.component.html',
  styleUrls: ['./submit-artwork.component.scss']
})
export class SubmitArtworkComponent {
  tableDataSource: any;
  artList: any = [];
  displayedColumns: string[] = [
    "slNo",
    "firstName",
    "lastName",
    "email",
    "phone",
    "createdAt",
    "action",
  ];

  files: any = [];

  isTable: Boolean = true;
  submitArtworkForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("downloadZipLink") private downloadZipLink: ElementRef;
  constructor(
    private commonService: CommonService,
    public subscribeFormBuilder: FormBuilder,
    private service: HttpService,
  ) {

  }


  ngOnInit(): void {


    this.submitArtworkForm = this.subscribeFormBuilder.group({
      firstname: [{ value: '', disabled: true }],
      lastname: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }],
      phone_number: [{ value: '', disabled: true }],
      sale_representative: [{ value: '', disabled: true }],
      splInstruction: [{ value: '', disabled: true }],
      event_name: [{ value: '', disabled: true }],
      sale_representative_mail: [{ value: '', disabled: true }],
      created_at: [{ value: '', disabled: true }],

    });

    this.getAllArtwork()


  }


  applyfilter(event: any) {

    this.tableDataSource.filter = event.target.value.trim().toLowerCase();

    if (this.tableDataSource.filteredData == 0) {
      this.commonService.error("No Matching Records Found");
    }
  }

  loadMaterial() {
    this.tableDataSource = new MatTableDataSource(this.artList);
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.paginator;

    //this.allGroupSource.sort = this.sort;
  }

  // sortData(sort: Sort) {
  //   const data = this.allContactDetails.slice();
  //   if (!sort.active || sort.direction === "") {
  //     this.allContactDetails = data;
  //     return;
  //   }
  //   this.allContactDetails = data.sort((a: any, b: any) => {
  //     const isAsc = sort.direction === "asc";
  //     switch (sort.active) {
  //       case "firstName":
  //         return this.commonService.compare(
  //           a.first_name.toLowerCase(),
  //           b.first_name.toLowerCase(),
  //           isAsc
  //         );
  //       case "lastName":
  //         return this.commonService.compare(
  //           a.last_name.toLowerCase(),
  //           b.last_name.toLowerCase(),
  //           isAsc
  //         );
  //       case "email":
  //         return this.commonService.compare(
  //           a.email.toLowerCase(),
  //           b.email.toLowerCase(),
  //           isAsc
  //         );
  //       case "phone":
  //         return this.commonService.compare(
  //           a.phone_number,
  //           b.phone_number,
  //           isAsc
  //         );
  //       case "region":
  //         return this.commonService.compare(a.region_name, b.region_name, isAsc);
  //       default:
  //         // Add a default return statement here
  //         return null

  //     }
  //   });
  //   this.tableDataSource = new MatTableDataSource(this.allContactDetails);
  //   this.tableDataSource.paginator = this.paginator;
  // }


  // this.contactForm.patchValue({
  //   firstname: this.dialogValue.pageValue["first_name"],
  //   lastname: this.dialogValue.pageValue["last_name"],
  //   email: this.dialogValue.pageValue["email"],
  //   // confirmemail: this.dialogValue.pageValue["email"],
  //   contactRegion: this.dialogValue.pageValue["region_id"] || 4,
  //   groupList: (this.dialogValue.pageValue["group_list"] != null) ? this.dialogValue.pageValue["group_list"].split(",").map(Number) : '',
  //   sourceList: (this.dialogValue.pageValue["source_list"] != null) ? this.dialogValue.pageValue["source_list"].split(",").map(Number) : '',
  //   mailingaddress: this.dialogValue.pageValue["mailing_address"],
  //   phoneNo: this.dialogValue.pageValue["phone_number"],
  //   notes: this.dialogValue.pageValue["contact_notes"]
  // });


  getAllArtwork() {

    try {

      this.service
        .get(`${environment.submitArtWork}/mail/getArtwork`)
        .subscribe({
          next: (res: any) => {

            if (res.statuCode === 200) {
              this.artList = res.data
              this.loadMaterial()
            }
          },
          error: (error: any) => {
            this.commonService.error(error.message);
          }
        })
    } catch (error) {

    }

  }


  viewDetail(obj: any) {
    try {
      this.isTable = false;
      this.submitArtworkForm.patchValue({
        firstname: obj.firstName || "",
        lastname: obj.lastName || "",
        email: obj.email || "",
        phone_number: obj.phone || "",
        sale_representative: obj.salessRepName || "",
        splInstruction: obj.specialInstruction || "",
        event_name: obj.companyName || "",
        sale_representative_mail: obj.salesRepresentative || '',
        created_at: obj.formated_CreatedDate || '',

      });

      this.files = JSON.parse(obj?.filesLinks)
      console.log(this.files)

    } catch (error) {
      console.log(error)
    }


  }


  back() {
    this.isTable = true;
    this.getAllArtwork();
    this.submitArtworkForm.patchValue({
      firstname: "",
      lastname: "",
      email: "",
      phone_number: "",
      sale_representative: "",
      splInstruction: "",
      event_name: "",
      sale_representative_mail: "",
      created_at: ""
    });

  }

  ngOnDestroy(): void {

    this.back()
  }

  extractFileNames(links: string) {
    // Split the string into an array of URLs


    // Split each URL by '/' and extract the last part (file name)
    const fileNames = links.split('/').pop();

    return fileNames;
  }

  downloadFile(file: string) {
    window.open(file, '_blank');
  }


}
