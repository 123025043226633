import { Injectable, inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/schema';
import { AuthenticationService } from '../user.service';
import { HttpService } from '../http.service';
import { environment } from 'src/app/environments/environment';

@Injectable({ providedIn: 'root' })
class AuthGuardService {
  currentUser: User;
  currentUserSubscription: Subscription;
  favIcon: any = document.querySelector('#appfavicon');
  constructor(
    private router: Router,
    private httpService: HttpService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((x: any) => {
        this.currentUser = x;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser')) {
      // logged in so return true
      // this.getCompanyDetails();
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['user/login']);
    return false;
  }

  getCompanyDetails() {
    this.httpService
      .get(
        `${environment.APIUrl}/api/CompanyDetails/getCompanyDetailsById?id=${this.currentUser.companyId}`
      )
      .subscribe({
        next: (companyDetails: any) => {
          // if(companyDetails['result']['company'] != undefined && companyDetails['result']['company'].length > 0){
          this.favIcon.href =
            companyDetails['result']['company'] != undefined &&
              companyDetails['result']['company'][0].comp_businessphoto != null &&
              companyDetails['result']['company'][0].comp_businessphoto
              ? companyDetails['result']['company'][0].logo
              : './../assets/images/favicon.ico';
        },
      });
  }
}




export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthGuardService).canActivate(next, state);
}