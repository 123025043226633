<div *ngIf="isTable">

  <div class="displayFlex alignItem_center spaceBetween mb_24">
    <div>
      <h2 class="fontsize18 fontWeight600 blackClr_text">Submit Artwork</h2>

    </div>
    <div class="inputGroup width_230">
      <div class="input-group-append"><span class="inputgroupText"><img class="dustyChimney_filter"
            src="../../../assets/images/new_img/search_icon.svg"></span></div>
      <input (keyup)="applyfilter($event)" matInput type="text" name="table_search" class="form-control float-right"
        placeholder="Search" />
    </div>
  </div>

  <div class="fullmatTable_wrapper  mb_24">
    <mat-table mat-table [dataSource]="tableDataSource" matSort matSortActive="created" matSortDisableClear
      matSortDirection="asc" id="example1" role="grid" aria-describedby="example1_info">
      <ng-container matColumnDef="slNo">
        <mat-header-cell *matHeaderCellDef>
          Sl. No
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          {{
          paginator.pageIndex * paginator.pageSize + (i + 1)
          }}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          First Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.firstName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{
          element.lastName && element.lastName !== 'undefined' ? element.lastName :""
          }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email Address
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.email }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Phone Number
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.phone }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Created At
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.formated_CreatedDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let element">


          <div class="displayFlex alignItem_center gap12">
            <div> <button (click)="viewDetail(element)" type="button" class="emptyButton">


                <img class="width_20" src="../../../assets/images/new_img/viewShow_icon.svg" />
              </button></div>

          </div>


        </mat-cell>
      </ng-container>


      <div>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </div>
    </mat-table>

  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]" showFirstlastButtons></mat-paginator>
</div>


<div class="FrameWhite p16" *ngIf="!isTable">
  <!-- <main class="main-content"> -->
  <div class="displayFlex alignItem_center spaceBetween mb_24">
    <div>
      <h2 class="fontsize18 fontWeight600 blackClr_text">Submit Artwork Detail</h2>
    </div>
    <div>
      <button class="MainBtn DeepBreath_btn" (click)="back()">Back</button>
    </div>
  </div>
  <hr>
  <form [formGroup]="submitArtworkForm">
    <div class="row mb_24">
      <div class="col-md-4">
        <div class="formGroup">
          <label for="firstname" class="labelText">First Name</label>
          <input type="text" formControlName="firstname" class="Maininput" />

        </div>
      </div>
      <div class="col-md-4">
        <div class="formGroup">
          <label for="lastname" class="labelText">Last Name</label>
          <input type="text" formControlName="lastname" class="Maininput" />

        </div>
      </div>
      <div class="col-md-4">
        <div class="formGroup">
          <label for="email" class="labelText">Email</label>
          <input type="text" formControlName="email" class="Maininput" />

        </div>
      </div>
    </div>

    <div class="row mb_24">
      <div class="col-md-4">
        <div class="formGroup">
          <label for="phone_number" class="labelText">Phone</label>
          <input type="text" formControlName="phone_number" class="Maininput" />

        </div>
      </div>
      <div class="col-md-4">
        <div class="formGroup">
          <label for="event_name" class="labelText">Company,Hotel,or Event Name</label>
          <input type="text" formControlName="event_name" class="Maininput" />

        </div>
      </div>
      <div class="col-md-4">
        <div class="formGroup">
          <label for="sale_representative" class="labelText">Sales Representative</label>
          <input type="text" formControlName="sale_representative" class="Maininput" />

        </div>
      </div>

    </div>

    <div class="row mb_24">
      <div class="col-md-4">
        <div class="formGroup">
          <label for="sale_representative" class="labelText">Sales Representative Mail</label>
          <input type="text" formControlName="sale_representative_mail" class="Maininput" />

        </div>
      </div>
      <div class="col-md-4">
        <div class="formGroup">
          <label for="sale_representative" class="labelText">Created At</label>
          <input type="text" formControlName="created_at" class="Maininput" />

        </div>
      </div>
    </div>


    <div class="formGroup mb_24">
      <label for="splInstruction" class="labelText">Special Instructions</label>
      <textarea rows="4" formControlName="splInstruction"></textarea>
    </div>

    <hr />
    <h2 class="fontsize18 fontWeight600 blackClr_text mb_12">Documents</h2>
    <div *ngFor="let item of files ; let i = index">
      <p class="paragraph_text mb_12"> <span> {{ i + 1 }}. {{extractFileNames(item) }} </span>
        <a (click)="downloadFile(item)" class="linktext">Download</a>
      </p>
    </div>



  </form>
  <!-- </main> -->
</div>