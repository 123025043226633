import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  private requestCount = 0;
  constructor(private spinner: NgxSpinnerService, private service: HttpService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    this.spinner.show();

    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.requestCount--;
            if (this.requestCount < 1) {
              this.spinner.hide();
            }
          }
        },
        error: (error) => {
          this.requestCount--;
          if (this.requestCount < 1) {
            this.spinner.hide();
          }
        },
        complete: () => {
          if (this.requestCount < 1) {
            this.spinner.hide();
          }
        },
      })
    )
  }
}
