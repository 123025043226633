import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupportComponent } from './support/support.component';
import { AuthGuard } from './common_service/_guards';
import {AccessGuard} from './common_service/_guards/access.guard';
import {SubmitArtworkComponent} from './submit-artwork/submit-artwork.component'

const routes: Routes = [
  
  {
    path: 'user',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'email',
    loadChildren: () =>
    import('./email/email.module').then((m) => m.EmailModule),
  },
  { path:'support', component: SupportComponent  },

  {
    path: 'calendar',
    loadChildren: () =>
      import('./calendar/calendars.module').then((m) => m.CalendarsModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'website',
    loadChildren: () =>
      import('./website/website.module').then((m) => m.WebsiteModule),
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./setting/setting.module').then((m) => m.SettingModule),
  },
  {
    path : 'social',
    loadChildren: ()=> 
    import('./socialpromo/socialpromo.module').then((m) => m.SocialPromoModule),
  },
  {
    path : 'submit_artwork',
    canActivate:[AuthGuard,AccessGuard],
    component: SubmitArtworkComponent ,
    data: {
      Permission: 'SUBMIT-ARTWORK', // Pass your custom data here
    },
  },
  {
    path: '',
    redirectTo: 'user/login',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() { }
}
