import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { User } from "../../schema/user";

@Injectable({
  providedIn: 'root'
})
export class AccessGuardService {
  currentUser: User;
  constructor(
    private router: Router,
    private commonService: CommonService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userDetails = sessionStorage.getItem('currentUser');
    const currentUser = userDetails && JSON.parse(userDetails)

    // Access custom data from the route
    const customData = route.data['Permission'];

    if (currentUser.role === "SuperAdmin") {
      return true
    } else {
      if (currentUser.permission.includes(customData)) {
        return true;
      }
      else {

       if(customData === currentUser.role ){
        return true;
       }
        this.commonService.error("You don't have permissions")
        this.router.navigate(["/dashboard"])
        return false;
      }
    }






  }


}

export const AccessGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(AccessGuardService).canActivate(next, state);
}
