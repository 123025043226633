


<div>
  <h5>Support</h5>
</div>


<p class="paragraph_text mb_24">
  Please reach out to us with any questions, concerns or feedback. We are
  here to serve you and help you be successful! </p>

<div class="container">
<div class="row">
<div class="col-md-4">
  <div class="supportFame">
    
<img src="../../assets/images/new_img/phone-icon.svg">

<div class="pb10">Call us at:</div>
<div ><a class=" linkColor blackClr_text" href="tel:(+1) 760-515-1008">
  (+1) 760-515-1008 </a></div>
  </div>


</div>
<div class="col-md-4">
  <div class="supportFame">
    
<img src="../../assets/images/new_img/email-icon.svg">

<div class="pb10">Email us at:</div>
<div ><a class="blackClr_text linkColor" href="mailto:support@sayshop.co">{{'support@sayshop.co'}}</a></div>
  </div>


</div>

<div class="col-md-4">
  <div class="supportFame">
    
<img src="../../assets/images/new_img/linkedin-icon.svg">

<div class="pb10">Via Linkedin:</div>
<div ><a class="blackClr_text" target="_blank" href="https://www.linkedin.com/company/theecode/">https://www.linkedin.com/company/theecode</a></div>
  </div>


</div>
</div>



</div>






 